export type MessageType = 'ZBPMessage';

export const allowedAttachmentsMimeTypesZBPMessage: Array<string> = [
  'pdf',
  'gif',
  'jpg',
  'jpeg',
  'png',
  'svg',
  'tiff',
  'tif',
  'txt',
  'ics',
  'ical',
  'ifb',
  'bmp',
  'rtf',
  'csv'
];

export const maxSizePerAttachmentZBPMessage: number = 5 * 1000 * 1000; // 5 MB in byte. Was defined by us. ZBP allows 25 * 1024 * 1024 = 25 MiB (01/25)

export const maxSizeAllAttachmentsZBPMessage: number = 5 * 1000 * 1000; // 5 MB in byte. Was defined by us. ZBP allows 25 * 1024 * 1024 = 25 MiB (01/25)

export const maxNumberAttachmentsZBPMessage: number = 200;

export const maxSizeZBPMessage: number = 1 * 1024 * 1024; // 1 MiB in byte

export const ZBPAlertTextSnippet: string =
  'das Zentrale Bürgerpostfach (BundID)';

function checkNoSizePerAttachment(currentFiles: FileList): string | null {
  let counter: number = 0;
  for (let i = 0; i < currentFiles.length; i++) {
    if (currentFiles[i].size <= 0) {
      counter++;
    }
  }

  if (counter !== 0) {
    if (counter === 1) {
      return 'Ein Anhang ist leer. Keine Anhänge wurden hinzugefügt!';
    } else {
      return `${counter} Anhänge sind leer. Keine Anhänge wurden hinzugefügt!`;
    }
  }

  return null;
}

function checkMaxSizePerAttachment(
  currentFiles: FileList,
  maxSizePerAttachment: number,
  alertTextSnippet: string
): string | null {
  const alertText: string = `Ein einzelne Datei als Anhang einer Nachricht an ${alertTextSnippet} kann maximal ${maxSizePerAttachment / 1000 / 1000} MB groß sein!`;
  for (let i = 0; i < currentFiles.length; i++) {
    if (currentFiles[i].size > maxSizePerAttachment) {
      return alertText;
    }
  }

  return null;
}

function checkMaxSizeAllAttachments(
  currentFiles: FileList,
  otherFileSizes: Array<number>,
  maxSizeAllAttachments: number,
  alertTextSnippet: string
): string | null {
  const alertText: string = `Alle Anhänge einer Nachricht an ${alertTextSnippet} können zusammen maximal ${maxSizeAllAttachments / 1000 / 1000} MB groß sein!`;
  let sizeAllAttachments: number = 0;
  sizeAllAttachments = otherFileSizes.reduce(
    (acc: number, curr: number) => acc + curr,
    0
  );
  for (let i = 0; i < currentFiles.length; i++) {
    sizeAllAttachments += currentFiles[i].size;
  }
  if (sizeAllAttachments > maxSizeAllAttachments) {
    return alertText;
  }
  return null;
}

function checkMaxNumberAttachments(
  currentFiles: FileList,
  otherFileSizes: Array<number>,
  maxNumberAttachments: number,
  alertTextSnippet: string
): string | null {
  const alertText: string = `Eine Nachricht an ${alertTextSnippet} kann maximal ${maxNumberAttachments} Anhänge haben!`;
  if (otherFileSizes.length + currentFiles.length > maxNumberAttachments) {
    return alertText;
  }
  return null;
}

function checkAllowedAttachmentsMimeTypes(
  currentFiles: FileList,
  allowedAttachmentsMimeTypes: Array<string>,
  alertTextSnippet: string
): string | null {
  const notAllowedExtensionsStr: Array<string> = [];
  let currentFileHasNoExtension: boolean = false;
  for (let i = 0; i < currentFiles.length; i++) {
    const fileExtension: string | undefined = currentFiles[i].name
      .split('.')
      .pop()
      ?.toLowerCase();
    if (fileExtension) {
      if (
        !allowedAttachmentsMimeTypes.includes(fileExtension ?? '') &&
        !notAllowedExtensionsStr.includes(fileExtension)
      ) {
        notAllowedExtensionsStr.push(fileExtension);
      }
    } else {
      currentFileHasNoExtension = true;
    }
  }
  const alertText: string = `als Anhang bei einer Nachricht an ${alertTextSnippet} unzulässig!`;
  if (currentFileHasNoExtension && notAllowedExtensionsStr.length !== 0) {
    return `Kein Dateityp und ${notAllowedExtensionsStr.join(', ')} sind ${alertText}`;
  } else if (currentFileHasNoExtension) {
    return `Kein Dateityp ist ${alertText}`;
  } else if (notAllowedExtensionsStr.length === 1) {
    return `Der Dateityp ${notAllowedExtensionsStr[0]} ist ${alertText}`;
  } else if (notAllowedExtensionsStr.length > 1) {
    return `Die Dateitypen ${notAllowedExtensionsStr.join(', ')} sind ${alertText}`;
  }

  return null;
}

export const getAlertTexts = (
  currentFiles: FileList,
  otherFileSizes: Array<number>,
  messageType: MessageType
): Array<string> => {
  switch (messageType) {
    case 'ZBPMessage':
      const checkMaxSizePerAttachmentAlertText: string | null =
        checkMaxSizePerAttachment(
          currentFiles,
          maxSizePerAttachmentZBPMessage,
          ZBPAlertTextSnippet
        );
      const checkMaxSizeAllAttachmentsAlertText: string | null =
        !checkMaxSizePerAttachmentAlertText
          ? checkMaxSizeAllAttachments(
              currentFiles,
              otherFileSizes,
              maxSizeAllAttachmentsZBPMessage,
              ZBPAlertTextSnippet
            )
          : null;
      return [
        checkNoSizePerAttachment(currentFiles),
        checkMaxNumberAttachments(
          currentFiles,
          otherFileSizes,
          maxNumberAttachmentsZBPMessage,
          ZBPAlertTextSnippet
        ),
        checkAllowedAttachmentsMimeTypes(
          currentFiles,
          allowedAttachmentsMimeTypesZBPMessage,
          ZBPAlertTextSnippet
        ),
        checkMaxSizePerAttachmentAlertText,
        checkMaxSizeAllAttachmentsAlertText
      ].filter((value): value is string => value !== null);
  }
};
