import { useContext, useState } from 'react';
import DecideApplicationModal from './DecideApplicationModal';
import { useSendMessage } from '../../../../hooks/useSendMessage';
import SendApplicationModal from './SendApplicationModal';
import { ContentTab } from '../../Schreibtisch';
import { caseDetailsDataContext } from '../../../../context/Context';

import { useSubmissionsAsNachrichten } from '../../../../hooks/useSubmissionsAsNachrichten';
import { Bescheidstatus } from '../../../../models/Status/Bescheidstatus';
import {
  UploadFilesConfig,
  useUploadFiles
} from '../../../../hooks/useUploadFiles';
import { ErrorComponentBehoerdenServer } from '../../../shared/Error/ErrorComponent';
import { FjdModal } from 'fjd-react-components';
import { NachrichtType } from '../../../../models/Nachrichten/NachrichtModel';

interface SwitchApplicationModalsProps {
  readonly onClose: () => void;
  readonly organisationUnitName: string;
  readonly caseId: string;
  readonly setContentTabState: (val: ContentTab) => void;
  readonly openAbschliessenModal: () => void;
}

export default function SwitchApplicationModals(
  props: SwitchApplicationModalsProps
) {
  const caseDetails = useContext(caseDetailsDataContext);
  const version: string = caseDetails.data.version;
  const [openSendenModal, setOpenSendenModal] = useState<boolean>(false);
  const [applicationIsPositiveDecided, setApplicationIsPositiveDecided] =
    useState<boolean | undefined>(undefined);
  const { sendMessage, loadingSend } = useSendMessage();
  const { mutate, error: useSubmissionsAsNachrichtenError } =
    useSubmissionsAsNachrichten({
      caseId: props.caseId
    });
  const [subject, setSubject] = useState<string>();
  const [text, setText] = useState<string>();
  const [shouldCloseCase, setShouldCloseCase] = useState<boolean>();

  const { uploadState, dispatch, confirmAction } = useUploadFiles({
    withConfirmation: true,
    deleteFilesWhenRemoveBescheid: true
  } as UploadFilesConfig);

  function openSendApplicationModal(
    applicationIsPositiveDecidedVar: boolean
  ): void {
    setApplicationIsPositiveDecided(applicationIsPositiveDecidedVar);
    setOpenSendenModal(true);
  }

  function returnToDecideApplicationModal(
    subject: string,
    text: string,
    shouldCloseCase: boolean
  ): void {
    setSubject(subject);
    setText(text);
    setShouldCloseCase(shouldCloseCase);
    setOpenSendenModal(false);
  }

  async function performSentApplicationActions(
    subject: String,
    text: String,
    antragAbschliessen: boolean
  ): Promise<void> {
    await sendMessage(
      subject,
      text,
      uploadState.bescheidUpload
        ? [uploadState.bescheidUpload, ...uploadState.additionalFiles]
        : uploadState.additionalFiles,
      props.caseId,
      true,
      NachrichtType.BESCHEID_DIGITAL,
      applicationIsPositiveDecided
        ? Bescheidstatus.POSITIV
        : Bescheidstatus.NEGATIV,
      version
    )
      .then(() => mutate())
      .finally(() => caseDetails?.refresh());

    props.onClose();
    if (antragAbschliessen) {
      props.openAbschliessenModal();
    } else {
      props.setContentTabState(ContentTab.KOMMUNIKATION);
    }
  }

  return (
    <div>
      <FjdModal
        appElement={document.querySelector('.fjd-base-layout') as HTMLElement}
        closeOnBackdropClick={false}
        heading={
          openSendenModal
            ? confirmAction.toConfirm
              ? confirmAction.heading
              : 'Bescheid versenden'
            : confirmAction.toConfirm
              ? confirmAction.heading
              : 'Antrag bescheiden'
        }
        id={openSendenModal ? 'send-application-modal' : 'attachment-modal'}
        testId={openSendenModal ? 'send-application-modal' : 'attachment-modal'}
        onClose={() => {
          props.onClose();
        }}
        open={useSubmissionsAsNachrichtenError}
        secondaryButtonDisabled={true}
        primaryButtonLabel={'Schließen'}
        onPrimaryButtonClick={() => props.onClose()}
      >
        <ErrorComponentBehoerdenServer
          errorCode={useSubmissionsAsNachrichtenError?.traceId}
        />
      </FjdModal>
      <DecideApplicationModal
        closeOnSecondaryButtonClick={() => {
          props.onClose();
        }}
        onContinueButtonClick={openSendApplicationModal}
        isPositiveDecided={applicationIsPositiveDecided}
        openAbschliessenModal={() => props.openAbschliessenModal()}
        fileDispatch={dispatch}
        isLoadingSend={loadingSend}
        filesUploadstate={uploadState}
        confirmAction={confirmAction}
        isOpen={!openSendenModal && !useSubmissionsAsNachrichtenError}
      />
      <SendApplicationModal
        closeOnSecondaryButtonClick={() => {
          props.onClose();
        }}
        returnOnSecondaryButtonClick={returnToDecideApplicationModal}
        performApplicationModalActions={performSentApplicationActions}
        isApplicationPositiveDecided={applicationIsPositiveDecided!}
        setApplicationIsPositiveDecided={setApplicationIsPositiveDecided}
        subject={subject}
        text={text}
        shouldCloseCase={shouldCloseCase}
        isLoadingSend={loadingSend}
        filesUploadstate={uploadState}
        fileDispatch={dispatch}
        confirmAction={confirmAction}
        isOpen={openSendenModal && !useSubmissionsAsNachrichtenError}
      />
    </div>
  );
}
