import { AxiosResponse } from 'axios';
import { useCallback } from 'react';
import { useApi } from './useApi';
import useSWR from 'swr';

export enum KeyType {
  UNWRAP = 'unwrapKey',
  SIGNING = 'sign'
}

export interface UploadedKey {
  alg: string;
  d: string;
  dp: string;
  dq: string;
  e: string;
  key_ops: Array<KeyType>;
  kid: string;
  kty: string;
  n: string;
  p: string;
  q: string;
  qi: string;
}

export const isKeyOfType = (key: UploadedKey, type: KeyType): boolean => {
  if (key.key_ops === undefined) {
    return false;
  }

  return key.key_ops.includes(type);
};

export const isDecryptionKey = (key: UploadedKey): boolean => {
  return isKeyOfType(key, KeyType.UNWRAP);
};

export const isSigningKey = (key: UploadedKey): boolean => {
  return isKeyOfType(key, KeyType.SIGNING);
};

export interface OrganizationalUnit {
  name: string;
  ouId: string | null;
  destinationDtos: Array<Destination>;
}

export interface Destination {
  destinationId: string;
  encryptionKid: string;
  privateSigningKey: UploadedKey | null; //
  privateDecryptionKey: UploadedKey | null; // Keymodel
  publicServiceTypeDto: Array<PublicServiceType>;
}
export interface PublicServiceType {
  identifier: string;
  submissionSchemaDtos: Array<SubmissionSchemaDto>;
}

export interface SubmissionSchemaDto {
  schemaUri: string;
  mimeType: string;
}

export interface ClientDestinationsModel {
  organizationalUnitDtos: Array<OrganizationalUnit>;
}

export default function useDestinations() {
  const { makeRequest } = useApi();

  const url: string = `/api/v1/configuration/destinations?source=FITCONNECT`;
  const {
    data: destinationsFromFitConnect,
    error,
    mutate,
    isValidating
  } = useSWR<ClientDestinationsModel>(url, {
    suspense: false
  });

  const {
    data: destinationsDataFromDb,
    error: errorLoad,
    mutate: mutateLoad
  } = useSWR<ClientDestinationsModel>(`/api/v1/configuration/destinations`, {
    suspense: false
  });

  const postDestination = useCallback(
    async (destinationModel: ClientDestinationsModel | undefined) => {
      return makeRequest<AxiosResponse>(
        `/api/v1/configuration/destinations`,
        'POST',
        undefined,
        undefined,
        undefined,
        destinationModel
      );
    },
    [makeRequest]
  );

  function triggerReload() {
    mutate().then();
  }

  return {
    postDestination,
    destinationsFromFitConnect,
    error,
    mutate,
    isValidating,
    destinationsDataFromDb,
    errorLoad,
    mutateLoad,
    triggerReload
  };
}
