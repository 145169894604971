import {
  IGroupDto,
  IUserRoleDto
} from '../../../../../models/Einstellungen/userRoleDto';
import { SortOrder } from '@efast_public/fjd-component-library/build/common/types/sorting';
import { UserRolesTable } from './UsersAndRolesManagementTable';

export type SortChangeParameter = {
  id: string;
  sortOrder: SortOrder;
  value: keyof UserRolesTable;
  index: number;
};

export const sortUserRolesTable: (
  userRolesData: Array<IUserRoleDto> | undefined,
  currentSortedColumn: SortChangeParameter | undefined
) => Array<IUserRoleDto> | undefined = function (
  userRolesData: Array<IUserRoleDto> | undefined,
  currentSortedColumn: SortChangeParameter | undefined
): Array<IUserRoleDto> | undefined {
  let retVal: Array<IUserRoleDto> | undefined = userRolesData;
  if (currentSortedColumn && currentSortedColumn.sortOrder !== 'none') {
    retVal = userRolesData?.sort((a, b) => {
      const valueA: string | boolean | Array<IGroupDto> =
        a[currentSortedColumn.value] ?? '';
      const valueB: string | boolean | Array<IGroupDto> =
        b[currentSortedColumn.value] ?? '';

      if (currentSortedColumn.sortOrder === 'ascending') {
        return valueA < valueB ? -1 : 1;
      } else {
        return valueA > valueB ? -1 : 1;
      }
    });
  }
  return retVal;
};
