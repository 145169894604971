import { AxiosResponse } from 'axios';
import { useState } from 'react';
import { Bescheidstatus } from '../models/Status/Bescheidstatus';
import {
  KommunikationRequestModel,
  KommunikationAttachmentModel
} from '../pages/Schreibtisch/SchreibtischBody/Kommunikation/NachrichtSenden/KommunikationRequestModel';

import useAlerts from './useAlerts';
import { useApi } from './useApi';
import { useProfile } from './useProfile';
import {
  BEHError,
  conflictErrorMsg,
  conflictZbpKeypairNotFound,
  ErrorType,
  useAlertErrorMsg
} from '../utils/Error/ErrorCode';
import { NachrichtType } from '../models/Nachrichten/NachrichtModel';

export function useSendMessage() {
  const { alert } = useAlerts();
  const { makeRequest } = useApi();
  const [loadingSend, setLoadingSend] = useState(false);
  const { profile, error } = useProfile();
  const [messageSent, setMessageSent] = useState(false);
  const isPostalischBeschieden = (nachrichtType: NachrichtType): boolean => {
    return nachrichtType === NachrichtType.BESCHEID_POSTALISCH;
  };
  const sendMessage = async (
    betreff: String,
    nachrichttext: String,
    attachments: Array<KommunikationAttachmentModel>,
    caseId: string,
    isBescheid: boolean,
    nachrichtType: NachrichtType,
    bescheidstatus: Bescheidstatus,
    caseVersion: string,
    applicationIsFinished?: boolean
  ) => {
    function handleError(e: Error | unknown) {
      const sendError = e as BEHError;
      switch (sendError.type) {
        case ErrorType.BEHOERDENCLIENT_PROBLEMS_UPDATE_NOT_ALLOWED:
          alert('error', conflictErrorMsg, 10000, true);
          break;
        case ErrorType.BEHOERDENCLIENT_PROBLEMS_ZBP_KEYPAIR_NOT_FOUND:
          alert('error', conflictZbpKeypairNotFound, null, true, false);
          break;
        default:
          alert(
            'error',
            useAlertErrorMsg.concat(` (${sendError?.traceId})`),
            10000,
            true
          );
      }
      setLoadingSend(false);
    }

    setLoadingSend(true);

    if (error) {
      handleError(error);
    } else {
      try {
        const sendMessageResponse = await makeRequest<AxiosResponse>(
          `/api/v1/cases/${caseId}/submissions`,
          'POST',
          undefined,
          undefined,
          'application/vnd.fjd.beh.message+json',
          {
            absender: `${profile?.firstName ?? ''} ${profile?.lastName ?? ''}`,
            betreff: betreff,
            nachrichttext: nachrichttext,
            attachments: attachments,
            isBescheid: isBescheid,
            nachrichtType: nachrichtType,
            bescheidstatus: bescheidstatus,
            caseVersion: caseVersion
          } as KommunikationRequestModel
        );

        if (
          !isPostalischBeschieden(nachrichtType) ||
          (isPostalischBeschieden(nachrichtType) && applicationIsFinished)
        ) {
          alert('success', 'Nachricht wurde erfolgreich versendet');
        } else {
          alert('success', 'Postalischer Bescheid wurde erfolgreich vermerkt');
        }
        setLoadingSend(false);
        setMessageSent(true);
        return sendMessageResponse;
      } catch (e) {
        handleError(e);
      }
    }
  };

  return { sendMessage, loadingSend, messageSent };
}
