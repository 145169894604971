import { Precondition } from '../ConfigAssistant';

export default interface IConfigAssistantStep {
  addProgress: (additionalProgress: number) => void;
  subtractProgress: (additionalProgress: number) => void;
  nextStep: () => void;
  prevStep: () => void;
  setButtonLabel: (label: string) => void;
  nextStepAvailable: (value: boolean) => void;
  addPrecondition: (preCondition: Precondition) => void;
  inputChanged: boolean;
  setInputChanged: (value: boolean) => void;
  setTryAgainButton: (value: boolean) => void;
}

export const nullImplementation: IConfigAssistantStep = {
  nextStepAvailable: () => {},
  addPrecondition: () => {},
  subtractProgress: () => {},
  addProgress: () => {},
  nextStep: () => {},
  prevStep: () => {},
  setButtonLabel: () => {},
  setInputChanged: () => {},
  inputChanged: false,
  setTryAgainButton: () => {}
};
