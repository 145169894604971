import { useState } from 'react';
import { KommunikationAttachmentModel } from '../pages/Schreibtisch/SchreibtischBody/Kommunikation/NachrichtSenden/KommunikationRequestModel';

import useAlerts from './useAlerts';
import { getAlertTexts } from '../utils/FileRestriction';
import { ConfirmAction } from '../models/UploadFiles/ConfirmAction';
import {
  FileUploadAction,
  ActionType
} from '../models/UploadFiles/FileUploadAction';
import { UploadFiles } from '../models/UploadFiles/UploadFiles';
import { fileToDataURL } from '../utils/dataURLConverter';

const initialState: UploadFiles = {
  additionalFiles: [],
  bescheidUpload: undefined
};

interface ConfirmationTexts {
  heading: string;
  message: string;
  buttonLabel: string;
}

const TEXT_CONFIRM_BESCHEID_DELETE: ConfirmationTexts = {
  heading: 'Wollen Sie die Bescheid-Datei wirklich entfernen?',
  message:
    'Um sicher zu gehen, dass der Antrag korrekt beschieden wird, werden mit dem Entfernen des Bescheid-Dokuments alle weiteren bisher hochgeladenen Dokumente ebenfalls entfernt.',
  buttonLabel: 'Bescheid und weitere Dokumente entfernen'
};

const TEXT_CONFIRM_ACTION: ConfirmationTexts = {
  heading: 'Wollen Sie diese Aktion wirklich durchführen?',
  message:
    'Um sicher zu gehen, dass der Antrag korrekt beschieden wird, werden damit alle bisher hochgeladenen Dokumente entfernt.',
  buttonLabel: 'Bescheid und weitere Dokumente entfernen'
};

const initConfirmActionState: ConfirmAction = {
  toConfirm: false,
  heading: '',
  message: '',
  buttonLabel: '',
  onConfirmation: () => {},
  onCancel: () => {}
};

export interface UploadFilesConfig {
  withConfirmation: boolean;
  deleteFilesWhenRemoveBescheid: boolean;
}

export function useUploadFiles(
  config: UploadFilesConfig = {
    withConfirmation: false,
    deleteFilesWhenRemoveBescheid: false
  }
) {
  const { alert } = useAlerts();
  const [uploadState, setUploadState] = useState<UploadFiles>(initialState);

  const [confirmAction, setConfirmAction] = useState<ConfirmAction>(
    initConfirmActionState
  );

  const addFiles = (fileList: FileList | null | Array<never>) => {
    if (fileList && !(fileList instanceof Array) && fileList.length > 0) {
      const otherFileSizes: Array<number> = uploadState.additionalFiles.map(
        (kam: KommunikationAttachmentModel) => kam.size
      );
      if (uploadState.bescheidUpload) {
        otherFileSizes.push(uploadState.bescheidUpload.size);
      }
      const errorTexts: Array<string> = getAlertTexts(
        fileList,
        otherFileSizes,
        'ZBPMessage'
      );
      if (errorTexts.length > 0) {
        errorTexts.forEach((errorText: string) => alert('warning', errorText));
      } else {
        Array.from(fileList).map(async (file: File) => {
          const payload = await fileToDataURL(file);

          setUploadState((prev: UploadFiles) => ({
            ...prev,
            additionalFiles: [
              ...prev.additionalFiles,
              {
                fileName: file.name,
                content: (payload as string).split(',')[1],
                mimeType: file.type,
                size: file.size,
                isBescheid: false
              }
            ]
          }));
        });
      }
    }
  };

  const removeFile = (index: number) => {
    setUploadState((prev: UploadFiles) => ({
      ...prev,
      additionalFiles: prev.additionalFiles.filter((_, i) => i !== index)
    }));
  };

  const removeAllFiles = () => {
    setUploadState((prev: UploadFiles) => ({
      ...prev,
      additionalFiles: []
    }));
  };

  const addBescheid = async (fileList: FileList | undefined | Array<never>) => {
    if (!fileList || fileList.length <= 0) {
      alert('warning', 'Es wurde keine Datei zum Hochladen ausgewählt');
      return;
    }

    if (fileList.length > 1) {
      alert('warning', 'Es kann nur ein Bescheid hochgeladen werden');
      return;
    }

    if (fileList && !(fileList instanceof Array) && fileList.length > 0) {
      const otherFileSizes: Array<number> = uploadState.additionalFiles.map(
        (kam: KommunikationAttachmentModel) => kam.size
      );
      const errorTexts: Array<string> = getAlertTexts(
        fileList,
        otherFileSizes,
        'ZBPMessage'
      );
      if (errorTexts.length > 0) {
        errorTexts.forEach((errorText: string) => alert('warning', errorText));
      } else {
        const file: File = fileList[0];
        const payload = await fileToDataURL(file);

        setUploadState((prev: UploadFiles) => ({
          ...prev,
          bescheidUpload: {
            fileName: file.name,
            content: (payload as string).split(',')[1],
            mimeType: file.type,
            size: file.size,
            isBescheid: true
          }
        }));
      }
    }
  };

  const removeBescheid = (onConfirmation?: () => void) => {
    if (config.withConfirmation && uploadState.additionalFiles.length > 0) {
      setConfirmAction(() => {
        return {
          toConfirm: true,
          heading: TEXT_CONFIRM_BESCHEID_DELETE.heading,
          message: TEXT_CONFIRM_BESCHEID_DELETE.message,
          buttonLabel: TEXT_CONFIRM_BESCHEID_DELETE.buttonLabel,
          onConfirmation: () => {
            setUploadState((prev: UploadFiles) => ({
              additionalFiles: config.deleteFilesWhenRemoveBescheid
                ? []
                : prev.additionalFiles,
              bescheidUpload: undefined
            }));
            setConfirmAction(initConfirmActionState);
            if (onConfirmation) {
              onConfirmation();
            }
          },
          onCancel: () => {
            setConfirmAction(initConfirmActionState);
          }
        };
      });
    } else {
      setUploadState((prev: UploadFiles) => ({
        ...prev,
        bescheidUpload: undefined
      }));
      if (onConfirmation) {
        onConfirmation();
      }
    }
  };

  const clearContent = (onConfirmation?: () => void) => {
    if (config.withConfirmation && uploadState.bescheidUpload) {
      setConfirmAction(() => {
        return {
          toConfirm: true,
          heading: TEXT_CONFIRM_ACTION.heading,
          message: TEXT_CONFIRM_ACTION.message,
          buttonLabel: TEXT_CONFIRM_ACTION.buttonLabel,
          onConfirmation: () => {
            setUploadState({
              additionalFiles: [],
              bescheidUpload: undefined
            });
            if (onConfirmation) {
              onConfirmation();
            }
            setConfirmAction(initConfirmActionState);
          },
          onCancel: () => {
            setConfirmAction(initConfirmActionState);
          }
        };
      });
    } else {
      setUploadState({
        additionalFiles: [],
        bescheidUpload: undefined
      });
      if (onConfirmation) {
        onConfirmation();
      }
    }
  };

  const dispatch = (action: FileUploadAction) => {
    switch (action.type) {
      case ActionType.ADD_ADDITIONAL_FILES:
        if (!action.files) {
          alert(
            'warning',
            'Beim Hochladen der optionalen Dateien ist etwas schief gelaufen'
          );
          return;
        }
        addFiles(action.files);
        break;
      case ActionType.REMOVE_ADDITIONAL_FILE:
        if (action.index === undefined) {
          alert('warning', 'Beim Löschen der Datei ist etwas schiefgelaufen');
          return;
        }
        removeFile(action.index);
        break;
      case ActionType.REMOVE_ALL_ADDITIONAL_FILES:
        removeAllFiles();
        break;
      case ActionType.ADD_BESCHEID:
        if (!action.files) {
          alert(
            'warning',
            'Beim Hochladen des Bescheids ist etwas schiefgelaufen'
          );
          return;
        }
        addBescheid(action.files).then();
        break;
      case ActionType.REMOVE_BESCHEID:
        removeBescheid(action.onConfirmation);
        break;
      case ActionType.REMOVE_ALL_FILES:
        clearContent(action.onConfirmation);
        break;
    }
  };

  return { uploadState, dispatch, confirmAction };
}
