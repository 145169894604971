import './NachrichtSendenModal.css';

import {
  FjdLoadingOverlay,
  FjdModal,
  FjdStack,
  FjdTextInput
} from 'fjd-react-components';
import { useContext, useState } from 'react';
import useAlerts from '../../../../../hooks/useAlerts';
import { useSendMessage } from '../../../../../hooks/useSendMessage';
import { useUploadFiles } from '../../../../../hooks/useUploadFiles';
import UploadsList from '../../../../shared/UploadsList';
import { useProfile } from '../../../../../hooks/useProfile';
import { Bescheidstatus } from '../../../../../models/Status/Bescheidstatus';
import { ErrorComponentBehoerdenServer } from '../../../../shared/Error/ErrorComponent';
import { FjdModalProps } from 'fjd-react-components/build/components/Modal/Modal';
import { caseDetailsDataContext } from '../../../../../context/Context';
import { useSubmissionsAsNachrichten } from '../../../../../hooks/useSubmissionsAsNachrichten';
import { NachrichtType } from '../../../../../models/Nachrichten/NachrichtModel';
import { ActionType } from '../../../../../models/UploadFiles/FileUploadAction';
import { maxSizeZBPMessage } from '../../../../../utils/FileRestriction';
import {
  errorTextOnNotAvailableBundIdConnection,
  isApplicationInFederalNetwork
} from '../../../../../utils/mandant';

interface NachrichtSendenModalProps {
  readonly onClose: () => void;
  readonly caseId: string;
  readonly caseVersion: string;
  readonly useSubmissionsAsNachrichtenError?: any; // any is defined by swr, cannot give a type
  readonly isOpen: boolean;
}

function NachrichtSendenModal(props: NachrichtSendenModalProps) {
  const caseDetails = useContext(caseDetailsDataContext);
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const { renderAlertContainer, alert } = useAlerts();
  const { sendMessage, loadingSend } = useSendMessage();
  const { uploadState, dispatch } = useUploadFiles();
  const { profile, error: useProfileError } = useProfile();
  const { mutate } = useSubmissionsAsNachrichten({
    caseId: caseDetails.data.caseId
  });

  const resetValues = () => {
    setSubject('');
    setText('');
    dispatch({ type: ActionType.REMOVE_ALL_ADDITIONAL_FILES });
  };

  const onClose = () => {
    resetValues();
    props.onClose();
  };

  const modalPropertiesBase: Partial<FjdModalProps> = {
    appElement: document.querySelector('.fjd-base-layout') as HTMLElement,
    heading: 'Nachricht versenden',
    id: 'nachricht-senden-modal',
    onClose: onClose,
    closeOnBackdropClick: false,
    open: props.isOpen
  };

  const modalPropertiesError: Partial<FjdModalProps> = {
    secondaryButtonDisabled: true,
    primaryButtonLabel: 'Schließen',
    onPrimaryButtonClick: () => onClose()
  };

  const modalPropertiesNoError: Partial<FjdModalProps> = {
    primaryButtonDisabled:
      !(text.length > 0 && subject.length > 0) ||
      loadingSend ||
      new TextEncoder().encode(text).length > maxSizeZBPMessage,
    secondaryButtonDisabled: loadingSend,
    onPrimaryButtonClick: () => {
      if (isApplicationInFederalNetwork()) {
        sendMessage(
          subject,
          text,
          uploadState.bescheidUpload
            ? [uploadState.bescheidUpload, ...uploadState.additionalFiles]
            : uploadState.additionalFiles,
          props.caseId,
          false,
          NachrichtType.NACHRICHT_DIGITAL,
          Bescheidstatus.NOCHNICHTBESCHIEDEN,
          props.caseVersion
        )
          .then(() => onClose())
          .finally(() => mutate());
      } else {
        alert(
          'error',
          errorTextOnNotAvailableBundIdConnection,
          null,
          true,
          false
        );
      }
    },
    primaryButtonLabel: 'Nachricht absenden',
    secondaryButtonLabel: 'Abbrechen',
    onSecondaryButtonClick: () => onClose()
  };

  const modalProperties: FjdModalProps =
    props.useSubmissionsAsNachrichtenError || useProfileError
      ? ({ ...modalPropertiesBase, ...modalPropertiesError } as FjdModalProps)
      : ({
          ...modalPropertiesBase,
          ...modalPropertiesNoError
        } as FjdModalProps);

  return (
    <FjdModal {...modalProperties}>
      <FjdLoadingOverlay loading={loadingSend}>
        {props.useSubmissionsAsNachrichtenError || useProfileError ? (
          <ErrorComponentBehoerdenServer errorCode={useProfileError?.traceId} />
        ) : (
          <FjdStack orientation="vertical">
            <FjdStack orientation="vertical" spacing="xs">
              <div>Absender:in der Nachricht</div>
              <FjdTextInput
                id="absender"
                type="text"
                value={`${profile?.firstName ?? '?'} ${
                  profile?.lastName ?? '?'
                }`}
                disabled
              />
            </FjdStack>
            <FjdStack orientation="vertical" spacing="xs">
              <div>Auftragsnummer & Antragstitel</div>
              <FjdTextInput
                id="auftragsnummer"
                type="text"
                minLength={1}
                value={`${caseDetails.data.auftragsnummer} • ${caseDetails.data.name}`}
                disabled
              />
            </FjdStack>
            <FjdStack orientation="vertical" spacing="xs">
              <div>Betreff</div>
              <FjdTextInput
                id="betreff"
                type="text"
                testId={'betreff'}
                minLength={1}
                onChange={(event) => setSubject(event.target.value)}
                value={subject}
                placeholder="Betreff"
              />
            </FjdStack>
            <FjdStack orientation="vertical" spacing="xs">
              <div>Nachrichtentext</div>
              <FjdTextInput
                id="nachrichtentext"
                type="text"
                testId={'nachrichtentext'}
                minLength={1}
                rows={6}
                onChange={(event) => setText(event.target.value)}
                value={text}
              />
            </FjdStack>
            <UploadsList
              uploadFiles={uploadState}
              dispatch={dispatch}
              hasUploadButtonEnabled={true}
              uploadButtonTextZeroFilesUploaded={
                'Anlage hinzufügen (max. 5 MB)'
              }
              uploadButtonTextAtLeastOneFileUploaded={
                'Weitere Anlage hinzufügen'
              }
              showPreview={true}
              hasIconsRightAligned={true}
            />
          </FjdStack>
        )}
      </FjdLoadingOverlay>
      {!props.useSubmissionsAsNachrichtenError &&
        !useProfileError &&
        renderAlertContainer()}
    </FjdModal>
  );
}

export default NachrichtSendenModal;
