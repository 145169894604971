import {
  Badge,
  Icon,
  IconButton,
  Menu,
  Table,
  Title
} from '@efast_public/fjd-component-library';
import { FjdSpinner } from 'fjd-react-components';
import React, {
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { SortOrder } from '@efast_public/fjd-component-library/build/common/types/sorting';
import { useUsersRolesGet } from '../../../../../hooks/useUsersRoles';
import {
  IGroupDto,
  IRoleDto,
  IUserRoleDto
} from '../../../../../models/Einstellungen/userRoleDto';
import {
  SortChangeParameter,
  sortUserRolesTable
} from './usersAndRolesManagementTableSort';
import {
  isLastAdministrator,
  isUserAdministrator,
  isUserRegistered,
  removeRoleSachbearbeitung
} from '../utils/usersAndRoles';
import UsersAndRolesManagementEditModal from './UsersAndRolesManagementEditModal';
import './usersAndRolesManagement.css';
import { useReadUserInviteConfig } from '../../../../../hooks/useReadUserInviteConfig';
import useAlerts from '../../../../../hooks/useAlerts';

function getStatusBadge(status: boolean) {
  switch (status) {
    case true:
      return (
        <Badge size="sm" iconBefore="checkmark-filled" variant="success">
          registriert
        </Badge>
      );
    default:
      return (
        <Badge size="sm" variant="inProgress">
          eingeladen
        </Badge>
      );
  }
}

interface TableColumn {
  label: string;
  value: string;
  isSticky?: boolean;
  sortable?: boolean;
  isHideable: boolean;
}

const tableColumns: Array<TableColumn> = [
  { label: 'Nachname', value: 'lastName', sortable: true, isHideable: true },
  { label: 'Vorname', value: 'firstName', sortable: true, isHideable: true },
  { label: 'E-Mail Adresse', value: 'email', isHideable: true },
  { label: 'Gruppe', value: 'group ', isHideable: true },
  { label: 'Rolle', value: 'role', isHideable: true },
  { label: 'Admin', value: 'isAdmin', isHideable: true },
  { label: 'Status', value: 'status', isHideable: true },
  { label: '', value: 'menu', isSticky: true, isHideable: false }
];

export interface UserRolesTable extends IUserRoleDto {}

type ModalOpen = 'editUserRoles' | 'none';

export interface ModalState {
  userId: string | undefined;
  modalOpen: ModalOpen;
}

interface UserAndRoleManagementTableProps {
  readonly width: string;
}

export default function UsersAndRolesManagementTable(
  props: UserAndRoleManagementTableProps
): React.ReactElement {
  const [currentSortedColumn, setCurrentSortedColumn] =
    useState<SortChangeParameter>();
  const [openRowMenu, setOpenRowMenu] = useState<string | null>(null);
  const [modalState, setModalState] = useState<ModalState>({
    userId: undefined,
    modalOpen: 'none'
  });

  const [hiddenColumns, setHiddenColumns] = useState<Array<number>>([]);

  const { alert } = useAlerts();
  const menuButtonRefs = useRef<{ [key: string]: HTMLButtonElement | null }>(
    {}
  );

  const {
    userRolesData,
    isValidating,
    mutate: loadUsersRoles
  } = useUsersRolesGet();

  const { inviteConfig } = useReadUserInviteConfig();

  const setAlertOnError = useCallback(
    (hasInviteConfig: boolean) => {
      if (!hasInviteConfig) {
        alert(
          'error',
          'Das hat leider nicht funktioniert. Bitte versuchen Sie es erneut oder probieren Sie es später noch einmal.',
          10000,
          true
        );
      }
    },
    [alert]
  );

  useEffect(() => {
    if (modalState.modalOpen === 'editUserRoles' && !inviteConfig) {
      setModalState({ userId: undefined, modalOpen: 'none' });
      setAlertOnError(false);
    }
  }, [modalState.modalOpen, inviteConfig, setAlertOnError]);

  const isModalOpen = (modalOpen: ModalOpen): boolean => {
    return modalState.modalOpen === modalOpen && !!inviteConfig;
  };

  const userRolesAndGroups = (): IUserRoleDto | undefined => {
    return userRolesData?.find((user) => user.id === modalState.userId);
  };

  const handleHideChange = (
    hiddenColumnsProp: SetStateAction<Array<number>>
  ): void => setHiddenColumns(hiddenColumnsProp);

  const handleSortChange: (sortedColumn: SortChangeParameter) => void =
    useCallback(
      (sortedColumn: SortChangeParameter) => {
        setCurrentSortedColumn(sortedColumn);
      },
      [setCurrentSortedColumn]
    );

  const sortedUserRoleManagementTable = useMemo(():
    | Array<IUserRoleDto>
    | undefined => {
    return sortUserRolesTable(userRolesData, currentSortedColumn);
  }, [currentSortedColumn, userRolesData]);

  return (
    <>
      <div style={{ width: props.width }}>
        {isValidating && (
          <div style={{ justifyContent: 'center', display: 'flex' }}>
            <FjdSpinner size="m" />
          </div>
        )}
        <Table
          title={
            <Title level={2} visualLevel={6}>
              Benutzer:innen und Rollen verwalten
            </Title>
          }
          ascendingLabel="Aufsteigend"
          descendingLabel="Absteigend"
          onHideChange={handleHideChange}
          hiddenColumns={hiddenColumns}
          hideColumnLabel="Spalte ausblenden"
          onSortChange={(column: {
            id: string;
            sortOrder: SortOrder;
            value: string;
            index: number;
          }) =>
            handleSortChange({
              ...column,
              value: column.value as keyof UserRolesTable
            })
          }
        >
          <Table.Columns sticky>
            {tableColumns.map(
              (column: TableColumn): ReactElement => (
                <Table.Column
                  isHideable={column.isHideable}
                  sortable={column.sortable}
                  key={column.value}
                  sticky={column.isSticky}
                  value={column.value}
                  data-testid={`testId-col-${column.value}`}
                >
                  {column.label}
                </Table.Column>
              )
            )}
          </Table.Columns>
          <Table.Rows>
            <>
              {sortedUserRoleManagementTable?.map(
                (user: UserRolesTable, index: number): ReactNode => {
                  return (
                    <Table.Row key={user.id}>
                      <Table.Cell
                        key={`${user.id}-${user.lastName}-${index}`}
                        data-testid={`testId-cell-${user.lastName}`}
                      >
                        {user.lastName}
                      </Table.Cell>
                      <Table.Cell
                        key={`${user.id}-${user.firstName}-${index}`}
                        data-testid={`testId-cell-${user.firstName}`}
                      >
                        {user.firstName}
                      </Table.Cell>
                      <Table.Cell key={`${user.id}-${user.email}-${index}`}>
                        {user.email}
                      </Table.Cell>
                      <Table.Cell
                        key={`${user.id}-groupName-${index}`}
                        data-testid={`testId-column-group-${index}`}
                      >
                        {user.groups.length === 1 &&
                        isUserAdministrator(user) ? (
                          '–'
                        ) : (
                          <ul>
                            {user.groups.map(
                              (
                                groupItem: IGroupDto,
                                index
                              ): false | ReactNode =>
                                groupItem.name !== 'Administration' && (
                                  <li
                                    className="no-list-style"
                                    key={`${groupItem.name}-${index}`}
                                  >
                                    {groupItem.name}
                                  </li>
                                )
                            )}
                          </ul>
                        )}
                      </Table.Cell>
                      <Table.Cell
                        key={`${user.id}-isAdmin-${index}`}
                        data-testid={`testId-column-role-${index}`}
                      >
                        {user.groups.length === 1 &&
                        isUserAdministrator(user) ? (
                          '–'
                        ) : (
                          <ul>
                            {user.groups.map(
                              (
                                groupItem: IGroupDto
                              ): ReactNode | Array<ReactNode> => {
                                if (groupItem.roles.length === 2) {
                                  const filteredRoles: Array<IRoleDto> =
                                    removeRoleSachbearbeitung(groupItem);
                                  return (
                                    <li
                                      className="no-list-style"
                                      key={groupItem.id}
                                      data-testid={`testId-byGroup-${groupItem.name}-role-${filteredRoles.map((role: IRoleDto): string => role.name)}`}
                                    >
                                      {filteredRoles.map(
                                        (role: IRoleDto): string => role.name
                                      )}
                                    </li>
                                  );
                                }
                                return groupItem.roles.map(
                                  (role: IRoleDto): ReactNode => (
                                    <li
                                      className="no-list-style"
                                      key={role.id}
                                      data-testid={`testId-byGroup-${groupItem.name}-role-${role.name}`}
                                    >
                                      {role.name}
                                    </li>
                                  )
                                );
                              }
                            )}
                          </ul>
                        )}
                      </Table.Cell>
                      <Table.Cell style={{ textAlign: 'center' }}>
                        {isUserAdministrator(user) && (
                          <Icon
                            data-testid={`testId-icon-checkmark-filled-for-user-${user.lastName}`}
                            variant="success"
                            name="checkmark-filled"
                          />
                        )}
                      </Table.Cell>
                      <Table.Cell
                        data-testid={`testId-status-${isUserRegistered(user) ? 'registered' : 'invited'}-for-user-${user.lastName}`}
                      >
                        {getStatusBadge(isUserRegistered(user))}
                      </Table.Cell>
                      <Table.Cell>
                        <IconButton
                          icon="overflow-menu-vertical"
                          variant="tertiary"
                          label="menu"
                          id={user.id}
                          key={`${user.id}-button`}
                          onClick={() =>
                            setOpenRowMenu((prev) =>
                              prev === user.id ? null : user.id
                            )
                          }
                          ref={(el) => (menuButtonRefs.current[user.id] = el)}
                        />
                      </Table.Cell>
                      <Menu
                        open={openRowMenu === user.id}
                        anchorElement={
                          menuButtonRefs.current[user.id] || undefined
                        }
                        onClose={() => setOpenRowMenu(null)}
                        className="usersAndRolesManagement-row-menu"
                      >
                        <Menu.MenuItem
                          icon="id-management"
                          id={user.id}
                          onClick={() => {
                            setModalState({
                              userId: user.id,
                              modalOpen: 'editUserRoles'
                            });
                            setOpenRowMenu(null);
                          }}
                        >
                          Benutzer:in verwalten
                        </Menu.MenuItem>
                        <Menu.MenuItem icon="trashcan">
                          Benutzer:in löschen
                        </Menu.MenuItem>
                      </Menu>
                    </Table.Row>
                  );
                }
              )}
            </>
          </Table.Rows>
        </Table>
      </div>
      <UsersAndRolesManagementEditModal
        isLastAdministrator={
          !!userRolesData && isLastAdministrator(userRolesData)
        }
        keycloakGroupRoles={inviteConfig}
        userRolesAndGroups={userRolesAndGroups()}
        onClose={() =>
          setModalState((state: ModalState) => {
            return { ...state, modalOpen: 'none' };
          })
        }
        loadUsersRoles={loadUsersRoles}
        isOpen={isModalOpen('editUserRoles')}
        headerText={'Benutzer:in verwalten'}
      />
    </>
  );
}
