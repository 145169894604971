import {
  IGroupDto,
  IRoleDto,
  IUserRoleDto,
  UserRoleUpdateDto
} from '../../../../../models/Einstellungen/userRoleDto';

export const isUserAdministrator: (
  row: IUserRoleDto | UserRoleUpdateDto
) => boolean = function (row: IUserRoleDto | UserRoleUpdateDto): boolean {
  return row.groups.some((group: IGroupDto) => group.name === 'Administration');
};

export const isUserRegistered: (row: IUserRoleDto) => boolean = function (
  row: IUserRoleDto
): boolean {
  return (row.firstName && row.lastName) !== null;
};

export function removeRoleSachbearbeitung(
  groupItem: IGroupDto
): Array<IRoleDto> {
  return groupItem.roles.filter(
    (role: IRoleDto): boolean => role.name !== 'Sachbearbeitung'
  );
}

export const isLastAdministrator = (
  usersGroupsAndRoles: Array<IUserRoleDto>
): boolean => {
  const administrators: Array<IGroupDto> = usersGroupsAndRoles
    .flatMap((user: IUserRoleDto): Array<IGroupDto> => user.groups)
    .filter((group: IGroupDto): boolean => group.name === 'Administration');
  return administrators.length <= 1;
};
