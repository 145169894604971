export enum ErrorType {
  BEHOERDENCLIENT_PROBLEMS_USER_LOGGED_IN = 'behoerdenclient/problems/userLoggedIn',
  BEHOERDENCLIENT_PROBLEMS_UPDATE_NOT_ALLOWED = 'behoerdenclient/problems/updateNotAllowed',
  BEHOERDENCLIENT_PROBLEMS_USER_CONFLICT = 'behoerdenclient/problems/userConflict',
  BEHOERDENCLIENT_PROBLEMS_CASES_LINKED = 'behoerdenclient/problems/casesLinked',
  BEHOERDENCLIENT_PROBLEMS_MULTIPLE_MANDANTS_LINKED = 'behoerdenclient/problems/multipleMandantsLinked',
  BEHOERDENCLIENT_PROBLEMS_CASES_MULTIPLE_USED_DESTINATIONS_LINKED = 'behoerdenclient/problems/multipleUsedDestination',
  BEHOERDENCLIENT_PROBLEMS_ZBP_KEYPAIR_NOT_FOUND = 'behoerdenclient/problems/zbpKeypairNotFound'
}

export enum ERROR_CASE_CLIENT {
  NOT_AUTHORIZED
}

export class BEHError extends Error {
  type: string;
  status: string | number | undefined;
  detail: string;
  traceId: string | undefined;

  constructor(
    type: string,
    msg: string | undefined,
    status: string | number | undefined,
    detail: string,
    traceId: string | undefined
  ) {
    super(msg);
    this.type = type;
    this.status = status;
    this.detail = detail;
    this.traceId = traceId;
  }
}

const errorMsgBackend =
  'Bitte wenden Sie sich mit folgendem Error-Code an Ihren Administrator.';

const errorMsgClient = 'Bitte wenden Sie sich an Ihren Administrator';

export const fjdAlertErrorBackendMsg = 'Es ist ein Fehler aufgetreten. '.concat(
  errorMsgBackend
);

export const useAlertErrorMsg = 'Fehler. '.concat(errorMsgBackend);

export const conflictErrorMsgWithReloadHint =
  'Das Objekt wurde seit dem Laden verändert. Bitte laden Sie die Seite neu und führen Sie die Aktion erneut durch.';

export const conflictErrorMsg =
  'Das Objekt wurde seit dem Laden verändert. Bitte führen Sie die Aktion erneut durch.';

export const userConflictErrorMsg = 'Diese Mailadresse wird bereits verwendet.';

export const conflictCasesLinked =
  'Zustellpunkte konnten nicht aktualiert werden, da noch Anträge im Mandanten vorhanden sind.';

export const conflictMultipleMandantsLinked =
  'Ein oder mehrere der ausgewählten Zustellpunkte ist bereits mit einem anderen Mandanten verknüpft. Eine erneute Zuweisung ist nicht möglich.';

export const conflictMultipleUsedDestination =
  'Ein oder mehrere der ausgewählten Zustellpunkte soll mehrfach zugewiesen werden.';

export const conflictZbpKeypairNotFound =
  'Die BundID ist aktuell nicht als Rückkanal konfiguriert. Eine Zustellung der Nachricht ist daher nicht möglich.';

const fjdAlertErrorClientMsg = `Es ist ein Fehler aufgetreten. ${errorMsgClient}`;

const error_NotAuthorized = `${fjdAlertErrorClientMsg} (Kein Zugriff).`;

export function getErrorMsgClient(errorCase: ERROR_CASE_CLIENT): string {
  switch (errorCase) {
    case ERROR_CASE_CLIENT.NOT_AUTHORIZED:
      return error_NotAuthorized;
    default:
      return `${fjdAlertErrorClientMsg}.`;
  }
}
