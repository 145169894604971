import {
  Button,
  Card,
  Dialog,
  ProgressBar,
  Spinner
} from '@efast_public/fjd-component-library';

import useConfigurationStep, {
  ConfigurationStep,
  getNextStep,
  getPrevStep
} from '../../hooks/useConfigurationStep';
import './ConfigAssistant.css';

import { ReactElement, useCallback, useEffect, useState } from 'react';
import IConfigAssistantStep from './ConfigAssistantSteps/IConfigAssistantStep';
import ConfigAssistantSteps from './ConfigAssistantSteps/ConfigAssistantSteps';
import { ErrorComponentBehoerdenServer } from '../shared/Error/ErrorComponent';
import {
  BEHError,
  conflictCasesLinked,
  conflictMultipleMandantsLinked,
  conflictMultipleUsedDestination,
  ErrorType
} from '../../utils/Error/ErrorCode';
import { FjdLoadingOverlay } from 'fjd-react-components';
import useAlerts from '../../hooks/useAlerts';
import useDestinations from '../../hooks/useDestinations';

export interface Precondition {
  condition: () => Promise<any>;
}

const noPrecondition: Precondition = {
  condition: () =>
    new Promise<void>((resolve) => {
      resolve();
    })
};

function ConfigAssistant(): ReactElement {
  const [inputChanged, setInputChanged] = useState<boolean>(false);

  const [progress, setProgress] = useState<number>(3);
  const [buttonLabel, setButtonLabel] = useState<string>('Weiter');
  const [stepAvailable, setStepAvailable] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tryAgainButton, setTryAgainButton] = useState<boolean>(false);

  const {
    error: useDestinationsError,
    isValidating: useDestinationsIsValidating,
    triggerReload
  } = useDestinations();

  const [precondition, setPrecondition] =
    useState<Precondition>(noPrecondition);

  const [safetyDialog, setSafetyDialog] = useState(false);

  const { updateConfiguratonStep, configurationStep, mutate, error } =
    useConfigurationStep();

  const { alert } = useAlerts();

  const errorAlert = [
    'error',
    'Da ist leider etwas schief gegangen',
    10000,
    true
  ] as const;

  useEffect(() => {
    switch (configurationStep?.configurationStep) {
      case ConfigurationStep.CHECKLIST:
        setProgress(2);
        break;

      case ConfigurationStep.FITCONNECTCLIENT:
        setProgress(16);
        break;

      case ConfigurationStep.DESTINATIONS:
        setProgress(30);
        break;

      case ConfigurationStep.DATAMODELS:
        setProgress(43);
        break;

      case ConfigurationStep.BUNDID:
        setProgress(57);
        break;

      case ConfigurationStep.LOGO:
        setProgress(71);
        break;

      case ConfigurationStep.FOOTER:
        setProgress(84);
        break;

      case ConfigurationStep.SUMMARY:
        setProgress(97);
        break;
    }

    setStepAvailable(false);
    setPrecondition(noPrecondition);
  }, [configurationStep]);

  const getPrecondition = (): Precondition => {
    return precondition;
  };

  const nextStep = () => {
    if (configurationStep) {
      const preCondition = getPrecondition();

      setLoading(true);
      preCondition
        .condition()
        .then(() => {
          const nextStep = getNextStep(configurationStep?.configurationStep);
          updateConfiguratonStep({
            configurationStep: nextStep
          })
            .then(() => {
              mutate().then();
              setPrecondition(noPrecondition);
              setStepAvailable(false);
            })
            .catch(() => {
              alert(...errorAlert);
            })
            .finally(() => {
              setLoading(false);
            });
        })
        .catch((e) => {
          const postDestinationsError = e as BEHError;
          if (!postDestinationsError) {
            alert(...errorAlert);
            setLoading(false);
            return;
          }

          switch (postDestinationsError.type) {
            case ErrorType.BEHOERDENCLIENT_PROBLEMS_CASES_LINKED:
              alert('error', conflictCasesLinked, 10000, true);
              return;
            case ErrorType.BEHOERDENCLIENT_PROBLEMS_CASES_MULTIPLE_USED_DESTINATIONS_LINKED:
              alert('error', conflictMultipleUsedDestination, 10000, true);
              return;
            case ErrorType.BEHOERDENCLIENT_PROBLEMS_MULTIPLE_MANDANTS_LINKED:
              alert('error', conflictMultipleMandantsLinked, 10000, true);
              return;
            default:
              alert(...errorAlert);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const tryAgainToFetchDataFromFitConnect = () => {
    triggerReload();
    if (!useDestinationsError) {
      setTryAgainButton(false);
    }
  };

  const prevStep = () => {
    if (
      configurationStep?.configurationStep !== ConfigurationStep.SUMMARY &&
      inputChanged
    ) {
      setSafetyDialog(true);
    } else {
      moveToPrevStep();
    }
  };

  const moveToPrevStep = () => {
    setSafetyDialog(false);
    if (configurationStep) {
      const prevStep = getPrevStep(configurationStep?.configurationStep);
      updateConfiguratonStep({
        configurationStep: prevStep
      }).then(() => {
        mutate().then();
      });
    }
  };

  const nextStepAvailable = (value: boolean) => {
    setStepAvailable(value);
  };

  const props: IConfigAssistantStep = {
    addProgress: (newProgress: number) => {
      setProgress((progress: number) => {
        const value = progress + newProgress;
        return value > 100 ? 100 : value;
      });
    },
    subtractProgress: (newProgress: number) => {
      setProgress((progress: number) => {
        const value = progress - newProgress;
        return value < 0 ? 0 : value;
      });
    },
    nextStep: () => nextStep(),
    prevStep: () => prevStep(),
    setButtonLabel: (value) => {
      setButtonLabel(value);
    },
    nextStepAvailable: (value: boolean) => nextStepAvailable(value),
    addPrecondition: useCallback(
      (condition: Precondition) => setPrecondition(condition),
      [setPrecondition]
    ),
    setInputChanged: (value: boolean) => setInputChanged(value),
    inputChanged: inputChanged,
    setTryAgainButton: (value: boolean) => setTryAgainButton(value)
  };

  const isFirstStep: boolean = configurationStep
    ? configurationStep.configurationStep === ConfigurationStep.CHECKLIST
    : true;

  if (error) {
    const behError: BEHError = error as BEHError;

    return <ErrorComponentBehoerdenServer errorCode={behError?.traceId} />;
  }
  return (
    <div className="container">
      <FjdLoadingOverlay loading={loading}>
        <Card className={progress >= 100 ? 'successState' : ''}>
          <Card.Content>
            <ProgressBar
              color={'primary-600'}
              value={progress}
              max={100}
            ></ProgressBar>
            <div className="cardContent">
              <div className="heading">Willkommen beim Behörden-Client</div>
              <ConfigAssistantSteps
                props={props}
                configurationStep={configurationStep?.configurationStep}
              />
            </div>
          </Card.Content>
          <Card.Footer>
            <div className="cardFooter">
              {isFirstStep ? (
                <Button href="https://www.fjd.de/kontakt/" variant="tertiary">
                  Ich brauche Hilfe
                </Button>
              ) : (
                <Button onClick={prevStep} variant="secondary">
                  Zurück
                </Button>
              )}
              {tryAgainButton ? (
                <Button
                  disabled={false}
                  variant="secondary"
                  onClick={tryAgainToFetchDataFromFitConnect}
                >
                  {useDestinationsIsValidating ? (
                    <Spinner
                      data-testid="logoConfigirationAssistantSpinner"
                      size="sm"
                    />
                  ) : (
                    buttonLabel
                  )}
                </Button>
              ) : (
                <Button
                  data-testid="nextStepButton"
                  disabled={!stepAvailable}
                  variant="primary"
                  onClick={nextStep}
                >
                  {buttonLabel}
                </Button>
              )}
            </div>
            {inputChanged && <div data-testid="inputChangedDiv" />}
          </Card.Footer>
        </Card>

        <Dialog
          actions={
            <>
              <Button onClick={() => moveToPrevStep()}>
                Trotzdem zurückgehen
              </Button>
              <Button onClick={() => setSafetyDialog(false)}>
                Weiter bearbeiten
              </Button>
            </>
          }
          header="Sind Sie sicher?"
          onClose={() => setSafetyDialog(false)}
          open={safetyDialog}
        >
          Sie haben Eingaben getätigt, die noch nicht gespeichert wurden. Wenn
          sie trotzdem zurückgehen, gehen diese Eingaben verloren.
        </Dialog>
      </FjdLoadingOverlay>
    </div>
  );
}
export default ConfigAssistant;
