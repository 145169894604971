import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Modal,
  Select,
  Spacer,
  Stack,
  Text
} from '@efast_public/fjd-component-library';
import {
  KeycloakAssignedGroups,
  KeycloakGroup
} from '../../../../../hooks/useInvite';
import React, { FormEvent, useEffect, useState } from 'react';
import {
  IGroupDto,
  IRoleDto,
  IUserRoleDto,
  UserRoleUpdateDto
} from '../../../../../models/Einstellungen/userRoleDto';
import { UserInviteConfig } from '../../../../../hooks/useReadUserInviteConfig';
import { SelectOption } from '@efast_public/fjd-component-library/build/common/components/BaseSelect/BaseSelect.types';
import { isUserAdministrator } from '../utils/usersAndRoles';
import { useUsersRolesPost } from '../../../../../hooks/useUsersRoles';
import useAlerts from '../../../../../hooks/useAlerts';
import { FjdLoadingOverlay } from 'fjd-react-components';

interface UsersAndRolesManagementModalProps {
  readonly isOpen: boolean;
  readonly onClose: () => void;
  readonly headerText: string;
  readonly userRolesAndGroups: IUserRoleDto | undefined;
  readonly keycloakGroupRoles: UserInviteConfig | undefined;
  readonly loadUsersRoles: () => void;
  readonly isLastAdministrator: boolean;
}

type UserGroupAndRolesChangeEvent =
  | FormEvent<HTMLInputElement>
  | Array<SelectOption>;

interface InitialGroupsAndRolesFormData {
  administration: { isAdmin: boolean; adminId: string };
  groupsAndRoles: Array<{
    groupNameId: string;
    groupName: string;
    availableRoles: Array<KeycloakGroup>;
    selectedRole: SelectOption | undefined;
  }>;
}

const initialGroupsAndRolesFormData: InitialGroupsAndRolesFormData = {
  administration: {
    isAdmin: false,
    adminId: ''
  },
  groupsAndRoles: []
};

export default function UsersAndRolesManagementEditModal(
  props: UsersAndRolesManagementModalProps
) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [userGroupsAndRolesUpdatedStatus, setUserGroupsAndRolesUpdatedStatus] =
    useState<UserRoleUpdateDto>({
      id: '',
      groups: []
    });
  const [groupsAndRolesForm, setGroupsAndRolesForm] =
    useState<InitialGroupsAndRolesFormData>(initialGroupsAndRolesFormData);
  const [initialState, setInitialState] = useState(groupsAndRolesForm);

  const { updateUsersGroupRoles } = useUsersRolesPost();
  const { alert } = useAlerts();

  useEffect(() => {
    const assignableGroups: Array<KeycloakAssignedGroups> = (() => {
      const groupRoles: Record<
        string,
        Array<KeycloakGroup>
      > = props.keycloakGroupRoles && props.keycloakGroupRoles.groupRoles
        ? props.keycloakGroupRoles.groupRoles
        : {};

      return Object.entries(groupRoles).map(
        ([groupName, subGroups]: [string, Array<KeycloakGroup>]) => ({
          groupName,
          subGroups: subGroups as Array<KeycloakGroup>
        })
      );
    })();

    const updatedGroups = assignableGroups.map(
      (item: KeycloakAssignedGroups) => {
        const selectedOption = generateSelectedOption(
          item.groupName,
          props.userRolesAndGroups
        );

        const groupNameId: string =
          props.userRolesAndGroups?.groups.find(
            (group: IGroupDto): boolean => group.name === item.groupName
          )?.id ?? '';

        return {
          groupName: item.groupName,
          groupNameId,
          availableRoles: item.subGroups,
          selectedRole: selectedOption
        };
      }
    );

    const updatedGroupAndRolesState = {
      administration: {
        isAdmin:
          !!props.userRolesAndGroups &&
          isUserAdministrator(props.userRolesAndGroups),
        adminId: grabAdministrationId(props.keycloakGroupRoles)
      },
      groupsAndRoles: updatedGroups
    };

    setGroupsAndRolesForm(updatedGroupAndRolesState);
    setInitialState(updatedGroupAndRolesState);
  }, [props.keycloakGroupRoles, props.userRolesAndGroups]);

  const generateSelectedOption = (
    groupName: string,
    userRolesAndGroups: IUserRoleDto | undefined
  ): SelectOption | undefined => {
    const userGroup: IGroupDto | undefined = userRolesAndGroups?.groups.find(
      (group: IGroupDto): boolean => group.name === groupName
    );

    if (userGroup) {
      const selectedRole: IRoleDto =
        userGroup.roles.find(
          (role: IRoleDto): boolean => role.name === 'Teamleitung'
        ) || userGroup.roles[0];

      if (selectedRole) {
        return {
          label: selectedRole.name,
          value: selectedRole.id
        };
      }
    }

    return undefined;
  };

  const grabAdministrationId: (data: UserInviteConfig | undefined) => string = (
    data: UserInviteConfig | undefined
  ): string => {
    const adminRole: KeycloakGroup | undefined = data?.systemRoles.find(
      (role: KeycloakGroup): boolean => role.name === 'Administration'
    );
    return adminRole ? adminRole.id : '';
  };

  const onSave = async () => {
    setIsLoading(true);
    try {
      await updateUsersGroupRoles(userGroupsAndRolesUpdatedStatus);
      props.onClose();
      props.loadUsersRoles();
    } catch (e) {
      console.error('Error while update user group role(s): ', e);
      alert(
        'error',
        'Das hat leider nicht funktioniert. Bitte versuchen Sie es erneut oder probieren Sie es später noch einmal.',
        10000,
        true
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancel = () => {
    setGroupsAndRolesForm(initialState);
    setUserGroupsAndRolesUpdatedStatus({ id: '', groups: [] });
    props.onClose();
  };

  const toUserRoleUpdateDto = (formState: typeof groupsAndRolesForm) => {
    const mappedGroups = formState.groupsAndRoles.map((group) => {
      const selectedRole = group.selectedRole
        ? {
            id: group.selectedRole.value,
            name: group.selectedRole.label
          }
        : null;

      const additionalRoles = [];
      if (selectedRole?.name === 'Teamleitung') {
        const sachbearbeitungRole: KeycloakGroup | undefined =
          group.availableRoles.find(
            (role: KeycloakGroup): boolean => role.name === 'Sachbearbeitung'
          );
        if (sachbearbeitungRole) {
          additionalRoles.push({
            id: sachbearbeitungRole.id,
            name: sachbearbeitungRole.name
          });
        }
      }

      return {
        id: group.groupNameId,
        name: group.groupName,
        roles: selectedRole ? [selectedRole, ...additionalRoles] : []
      };
    });

    if (formState.administration.isAdmin) {
      mappedGroups.push({
        id: formState.administration.adminId,
        name: 'Administration',
        roles: []
      });
    }

    return mappedGroups;
  };

  const handleUserGroupAndRolesChanges = (
    event: UserGroupAndRolesChangeEvent | boolean,
    groupName: string,
    groupNameId: string
  ) => {
    const updateState = (updatedFormState: typeof groupsAndRolesForm) => {
      setGroupsAndRolesForm(updatedFormState);

      setUserGroupsAndRolesUpdatedStatus((prevState: UserRoleUpdateDto) => ({
        ...prevState,
        id: props.userRolesAndGroups?.id ?? '',
        groups: toUserRoleUpdateDto(updatedFormState)
      }));
    };

    if (groupName === 'Administration' && typeof event === 'boolean') {
      const updatedFormState = {
        ...groupsAndRolesForm,
        administration: {
          ...groupsAndRolesForm.administration,
          isAdmin: event
        }
      };
      updateState(updatedFormState);
    }

    if (Array.isArray(event) && groupName) {
      const updatedFormState = {
        ...groupsAndRolesForm,
        groupsAndRoles: groupsAndRolesForm.groupsAndRoles.map((group) =>
          group.groupName === groupName
            ? { ...group, selectedRole: event[0], groupNameId }
            : group
        )
      };
      updateState(updatedFormState);
    }
  };

  const isButtonDisabled: (
    userGroupsAndRolesUpdatedStatus: UserRoleUpdateDto
  ) => boolean = (
    userGroupsAndRolesUpdatedStatus: UserRoleUpdateDto
  ): boolean => {
    const isRoleAssigned: boolean = userGroupsAndRolesUpdatedStatus.groups.some(
      (entry: IGroupDto): boolean => entry.roles.length > 0
    );
    const isRoleAdminAssigned: boolean = isUserAdministrator(
      userGroupsAndRolesUpdatedStatus
    );

    return !isRoleAssigned && !isRoleAdminAssigned;
  };

  return (
    <Modal open={props.isOpen} onClose={handleCancel}>
      <Modal.Header id="modal-editUserRoles-header">
        {props.headerText}
      </Modal.Header>
      <FjdLoadingOverlay loading={isLoading}>
        <Modal.Content>
          {!props.userRolesAndGroups?.lastName &&
            !props.userRolesAndGroups?.firstName && (
              <>
                <Alert
                  variant="info"
                  message="Die Registrierung durch die Benutzer:in ist noch nicht erfolgt. Nachname und Vorname folgen."
                />
                <Spacer size="m" />
              </>
            )}
          <Stack
            alignItems="center"
            justifyContent="space-between"
            direction={{
              l: 'row',
              m: 'row',
              s: 'column',
              xl: 'row',
              xs: 'column'
            }}
          >
            <Checkbox
              disabled={
                (!props.userRolesAndGroups?.firstName &&
                  !props.userRolesAndGroups?.lastName) ||
                (props.isLastAdministrator &&
                  props.userRolesAndGroups &&
                  isUserAdministrator(props.userRolesAndGroups))
              }
              data-testid="admin-checkbox"
              label="Admin"
              name="adminStatus"
              checked={groupsAndRolesForm.administration.isAdmin}
              onChange={(event: FormEvent<HTMLInputElement>) => {
                const isChecked: boolean = event.currentTarget.checked;
                handleUserGroupAndRolesChanges(
                  isChecked,
                  'Administration',
                  groupsAndRolesForm.administration.adminId
                );
              }}
            />
            <Stack alignItems="flex-end">
              <Text strong size="s">
                {props.userRolesAndGroups?.lastName &&
                props.userRolesAndGroups?.firstName
                  ? `${props.userRolesAndGroups?.lastName}, ${props.userRolesAndGroups?.firstName}`
                  : `Nachname, Vorname`}
              </Text>
              <Text data-testid={`${props.userRolesAndGroups?.email}`} size="s">
                {props.userRolesAndGroups?.email}
              </Text>
            </Stack>
          </Stack>
          <Divider verticalSpacing="l" />
          <Stack spacing="s">
            {groupsAndRolesForm.groupsAndRoles.map((item, index) => {
              return (
                <Stack
                  key={`${item.groupName}-${index}`}
                  data-testid={`groupName-${item.groupName}`}
                  spacing="5xl"
                  alignItems="center"
                  direction="row"
                >
                  <Text strong style={{ minWidth: '10rem' }}>
                    {item.groupName}
                  </Text>
                  <div style={{ width: '18rem' }}>
                    <Select
                      data-testid={`groupName-${item.groupName}-selectedGroupRole-${item.selectedRole?.label}`}
                      type="text"
                      placeholder="–"
                      options={item.availableRoles.map(
                        (
                          role: KeycloakGroup
                        ): { label: string; value: string } => ({
                          label: role.name,
                          value: role.id
                        })
                      )}
                      selectedOptions={
                        item.selectedRole ? [item.selectedRole] : []
                      }
                      onChange={(e: Array<SelectOption>) =>
                        handleUserGroupAndRolesChanges(
                          e,
                          item.groupName,
                          item.groupNameId
                        )
                      }
                    />
                  </div>
                </Stack>
              );
            })}
          </Stack>
        </Modal.Content>
        <Modal.Footer direction="rtl" alignment="none">
          <Button
            data-testid="cancel-button-inModalEditUserRoles"
            size="sm"
            variant="tertiary"
            onClick={handleCancel}
          >
            Abbrechen
          </Button>
          <Button
            data-testid="save-button-inModalEditUserRoles"
            size="sm"
            variant="primary"
            onClick={onSave}
            disabled={isButtonDisabled(userGroupsAndRolesUpdatedStatus)}
          >
            Speichern
          </Button>
        </Modal.Footer>
      </FjdLoadingOverlay>
    </Modal>
  );
}
