import useSWR, { KeyedMutator } from 'swr';
import { AxiosResponse } from 'axios';
import {
  IUserRoleDto,
  UserRoleUpdateDto
} from '../models/Einstellungen/userRoleDto';
import { useApi } from './useApi';

interface IUsersRolesGet {
  userRolesData: Array<IUserRoleDto> | undefined;
  error: any;
  isValidating: boolean;
  mutate: KeyedMutator<Array<IUserRoleDto>>;
}

interface IUsersRolesPost {
  updateUsersGroupRoles: (
    updatedUsersGroupRolesData: UserRoleUpdateDto
  ) => Promise<any>;
}

export function useUsersRolesGet(): IUsersRolesGet {
  const {
    data: userRolesData,
    error,
    isValidating,
    mutate
  } = useSWR<Array<IUserRoleDto>>(`/api/v1/config/usersRoles`, {
    suspense: false
  });

  return { userRolesData, error, isValidating, mutate };
}

export function useUsersRolesPost(): IUsersRolesPost {
  const { makeRequest } = useApi();

  const updateUsersGroupRoles: (
    updatedUsersGroupRolesData: UserRoleUpdateDto
  ) => Promise<any> = async (updatedUsersGroupRolesData: UserRoleUpdateDto) => {
    return await makeRequest<AxiosResponse>(
      `/api/v1/config/usersRoles`,
      'PUT',
      undefined,
      undefined,
      'application/json',
      updatedUsersGroupRolesData
    );
  };

  return { updateUsersGroupRoles };
}
